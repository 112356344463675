import React, { useState } from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import ReactSVG from 'react-svg'

import Grid from 'src/UIComponents/Grid/Grid'
import SubTitle from 'src/UIComponents/Title/SubTitle'
import Label from 'src/UIComponents/Label/Label'
import Button from 'src/UIComponents/Button/ButtonDefault'
import FormZ from 'src/UIComponents/FormZ/FormZ'
import InputDefaultFactory from 'src/Components/FormFactories/InputDefaultFactory'
import { getFormOptionsWithValidation } from 'src/Utils/FormUtils'

import { darkGray, smallText } from 'src/Styles/settings/Constants'
import LabelCopyToClipboard from '../LabelCopyToClipboard/LabelCopyToClipboard'
import { MFASingleComponent } from '../../Components/MFA/mfaSingleComponent'
import { updateCustomMarketplaceSlug } from '../../Actions/custom'
import { useDispatch, useSelector } from 'react-redux'

const DashboardURL = ({ color, disabled, hideButtonEdit, handleEdit, handleOnChange, handleCancel, formValues, slugShowValidation, isLoading, struct }) => {
  const formOptions = {
    fields: {
      URLPrefix: {
        label: 'Prefixo da URL',
        factory: InputDefaultFactory,
        autocomplete: false,
        config: {
          id: 'URLPrefix',
          disabled: disabled
        }
      }
    }
  }
  const [showMFA, setShowMFA] = useState(false)
  const { marketplace } = useSelector((state) => ({
    marketplace: state.marketplace
  }))

  const dispatch = useDispatch()

  const onSubmitMFA = () => {
    setShowMFA(true)
  }

  const onValidMFA = () => {
    dispatch(
      updateCustomMarketplaceSlug(marketplace.id, {
        name: marketplace.name,
        slug: formValues.URLPrefix
      })
    )
  }

  return (
    <>
      {showMFA && <MFASingleComponent showComponent={setShowMFA} onSubmit={onValidMFA} />}
      <Grid.Row>
        <Grid.Col offset={1} cols={15}>
          <Grid.Row>
            <Grid.Col cols={15}>
              <SubTitle line>
                URL <strong>do Dashboard</strong>
              </SubTitle>
            </Grid.Col>
            <Grid.Col cols={1}>
              {!hideButtonEdit && (
                <EditButton ghost outline onClick={() => handleEdit('dashboardURL')} icon='/assets/icons/actions/edit.svg'>
                  Editar
                </EditButton>
              )}
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col cols={7}>
              <Label color={darkGray}>Escolha o prefixo da URL de acesso ao seu Dashboard e ao Minha Conta.</Label>
            </Grid.Col>
          </Grid.Row>
          <Grid.Row>
            <Grid.Col cols={16}>
              <Wrapper>
                <ImgContainer>
                  <BrowserUrlImg color={color} src='/assets/imgs/browser-url.svg' />
                  <LabelDashboard>
                    <LabelCopyToClipboard text={`${formValues.URLPrefix}.dash.zoop.com.br`}>{`${formValues.URLPrefix}.dash.zoop.com.br`}</LabelCopyToClipboard>
                  </LabelDashboard>
                  <LabelMinhaConta>
                    <LabelCopyToClipboard text={`${formValues.URLPrefix}.minhaconta.zoop.com.br`}>
                      {`${formValues.URLPrefix}.minhaconta.zoop.com.br`}
                    </LabelCopyToClipboard>
                  </LabelMinhaConta>
                </ImgContainer>
                <FormWrapper>
                  <Grid.Row noMargin>
                    <Grid.Col cols={10}>
                      <FormZ
                        options={slugShowValidation ? getFormOptionsWithValidation(formOptions, formValues, struct) : formOptions}
                        struct={struct}
                        values={formValues}
                        onChange={handleOnChange}
                        disabledForm={disabled}
                      />
                      <br />
                    </Grid.Col>
                  </Grid.Row>
                  <Grid.Row auto noMargin>
                    <Label overflow bold color={darkGray} fontSize={smallText}>
                      {`${formValues.URLPrefix}.dash.zoop.com.br`}
                    </Label>
                  </Grid.Row>
                  <Grid.Row auto>
                    <MinhaContaContainer>
                      <Label overflow bold color={darkGray} fontSize={smallText}>
                        {`${formValues.URLPrefix}.minhaconta.zoop.com.br`}
                      </Label>
                      <IconLink
                        src='/assets/icons/actions/open-link.svg'
                        onClick={() => window.open(`https://${formValues.URLPrefix}.minhaconta.zoop.com.br`)}
                      />
                    </MinhaContaContainer>
                  </Grid.Row>
                  {!disabled && (
                    <Grid.Row>
                      <Grid.Col cols={16}>
                        <ButtonsContainer>
                          <Button inversed onClick={handleCancel}>
                            Cancelar
                          </Button>
                          <Button
                            isLoading={isLoading}
                            onClick={() => {
                              onSubmitMFA()
                            }}
                          >
                            Salvar Alterações
                          </Button>
                        </ButtonsContainer>
                      </Grid.Col>
                    </Grid.Row>
                  )}
                </FormWrapper>
              </Wrapper>
            </Grid.Col>
          </Grid.Row>
        </Grid.Col>
      </Grid.Row>
    </>
  )
}

DashboardURL.propTypes = {
  color: PropTypes.string,
  disabled: PropTypes.bool,
  handleEdit: PropTypes.func,
  handleOnChange: PropTypes.func,
  formValues: PropTypes.object,
  handleCancel: PropTypes.func,
  slugShowValidation: PropTypes.bool,
  struct: PropTypes.func,
  hideButtonEdit: PropTypes.bool,
  isLoading: PropTypes.bool
}

export default DashboardURL

const Wrapper = styled.div`
  display: flex;
`
const FormWrapper = styled.div`
  width: 320px;
`

const ImgContainer = styled.div`
  position: relative;
  display: flex;
  width: fit-content;
  margin-right: 22px;
`

const EditButton = styled(Button)`
  display: flex;
  width: 88px;
  margin-top: -7px;
`

const BrowserUrlImg = styled(ReactSVG)``

const LabelDashboard = styled.div`
  position: absolute;
  max-width: 220px;
  top: 36px;
  left: 70px;
`

const LabelMinhaConta = styled.div`
  position: absolute;
  max-width: 220px;
  top: 60px;
  left: 70px;
`

const ButtonsContainer = styled.div`
  display: flex;
  width: 300px;
  margin-bottom: 30px;

  button:first-child {
    margin-right: 5px;
  }
`

const MinhaContaContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const IconLink = styled.img`
  width: 12px;
  height: 12px;
  margin-left: 3px;
  padding-bottom: 1px;
`
