import styled from 'styled-components'
import Label from 'src/UIComponents/Input/Label'
import CheckboxInput from 'src/UIComponents/Checkbox/CheckboxInput'
import { smallMargin } from '../../../Styles/settings/Constants'

export const List = styled.ul``

export const ListItem = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  position: relative;

  ${Label} {
    margin: 0;
  }

  ${CheckboxInput} {
    margin-right: ${smallMargin};
  }

  &:hover::after {
    content: '';
    width: 2px;
    height: 18px;
    background: ${(props) => props.theme.color};
    position: absolute;
    top: 5px;
    left: -11px;
  }
`
