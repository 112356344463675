import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { isEmpty, isEqual } from 'lodash'

import ButtonDropdown from 'src/UIComponents/ButtonDropdown/ButtonDropdown'
import InputDatePicker from 'src/UIComponents/InputDatePicker/InputDatePicker'

import Icon from 'src/UIComponents/Icon/Icon'
import { UppercaseFirstLetter } from 'src/Utils/Utils'
import { DatePickerInputContainer, Wrapper } from './DateInput.styled'

moment.locale('pt-br')

const DateInput = ({ placeholder, value, defaultValue, parentLabel, options, onSubmit, onClear, gteLimit, typeChange = null, noBackground }) => {
  const inputDateFrom = useRef(null)
  const inputDateTo = useRef(null)
  const [label, setLabel] = useState(parentLabel)
  const [dateInput, setDateInput] = useState(value)
  const [date, setDate] = useState(value)
  const [errors, setErrors] = useState()

  const ERRORS = {
    gteLimitError: `Limite ${gteLimit.format('DD/MM/YYYY')}`,
    lteBeforeGteError: `Deve ser maior ou igual a ${dateInput && moment(dateInput.gte).format('DD/MM/YYYY')}`
  }

  const locationPath = UppercaseFirstLetter(window.location.pathname.split('/')[1])

  const handleClear = () => {
    setDate()
    setDateInput()
    onClear()
  }

  const validateDates = () => {
    let error = {}
    const from = dateInput && moment(dateInput.gte, 'DD/MM/YYYY')
    const to = dateInput && moment(dateInput.lte, 'DD/MM/YYYY')

    if (from && from.isValid()) {
      if (gteLimit) {
        const limit = moment(gteLimit.toDate())
        error.gteError = from.isBefore(limit) && 'gteLimitError'
      }

      if (to && to.isValid()) {
        const isDateBeforeFrom = to.isBefore(from)
        if (isDateBeforeFrom) {
          error.lteError = 'lteBeforeGteError'
        }
      }
    }

    return error
  }

  const handleOnChangeInput = (key, value) => {
    if (value.length === 10) {
      let valueAsDate = null
      if (key === 'lte') {
        valueAsDate = moment(value, 'DD/MM/YYYY').endOf('day')
      } else {
        valueAsDate = moment(value, 'DD/MM/YYYY').startOf('day')
      }

      setDateInput({
        ...dateInput,
        [key]: valueAsDate.toDate()
      })
    } else if (value.length === 0) {
      setDateInput({
        ...dateInput,
        [key]: undefined
      })
    }
  }

  const handleLabel = date => {
    date = {
      lte: date && moment(date.lte).endOf('day').toDate(),
      gte: date && moment(date.gte).startOf('day').toDate()
    }

    const index = date ? options.findIndex(i => isEqual(i.value, date)) : -1
    if (~index) {
      return setLabel(options[index].label)
    }

    if (date) {
      const dateFormat = 'DD/MM/YYYY'
      const from = date.gte && moment(date.gte).format(dateFormat)
      const to = date.lte && moment(date.lte).format(dateFormat)

      const label = from && to
        ? <>{from}<Icon src='/assets/icons/arrow/mini-arrow-right.svg' />{to}</>
        : (from || to)

      setLabel(label)
    } else {
      setLabel(date)
    }
  }

  const handleSubmit = (value) => {
    const dateToSubmit = {
      gte: value.gte || moment().startOf('day').toDate(),
      lte: value.lte || moment().endOf('day').toDate()
    }

    if (isEmpty(errors) || (!errors.gteError && !errors.lteError)) {
      setDate(dateToSubmit)
      onSubmit(dateToSubmit)
    }
  }

  const handleDateInputSubLabel = (value) => {
    if (value) {
      return moment(value).isValid() ? moment(value).format('dddd') : undefined
    }
  }

  const handleDateInputValue = (value) => {
    if (value) {
      return moment(value).isValid() ? moment(value).format('DD/MM/YYYY') : undefined
    }
  }

  const datePickers = [
    {
      component: () => <DatePickerInputContainer>
        <InputDatePicker
          label='De'
          inputRef={inputDateFrom}
          subLabel={dateInput ? handleDateInputSubLabel(dateInput.gte) : null}
          value={dateInput ? handleDateInputValue(dateInput.gte) : undefined}
          onChange={(value) => handleOnChangeInput('gte', value)}
          placeholder='DD/MM/AAAA'
          hasError={errors && errors.gteError}
          errorMessage={errors && errors.gteError && `(${ERRORS[errors.gteError]})`}
        />
      </DatePickerInputContainer>
    },
    {
      component: () => <DatePickerInputContainer>
        <InputDatePicker
          label='Até'
          inputRef={inputDateTo}
          subLabel={dateInput ? handleDateInputSubLabel(dateInput.lte) : undefined}
          value={dateInput ? handleDateInputValue(dateInput.lte) : undefined}
          onChange={(value) => handleOnChangeInput('lte', value)}
          placeholder='DD/MM/AAAA'
          hasError={errors && errors.lteError}
          errorMessage={errors && errors.lteError && `(${ERRORS[errors.lteError]})`}
        />
      </DatePickerInputContainer>
    }
  ]

  useEffect(() => {
    if (dateInput) {
      const validationErrors = validateDates()
      setErrors(validationErrors)
    } else {
      setErrors()
    }
  }, [dateInput])

  useEffect(() => {
    handleLabel(date)
  }, [date])

  useEffect(() => {
    if (value) {
      const dateValue = {
        gte: moment(value.gte).toDate(),
        lte: moment(value.lte).toDate()
      }
      setDate(dateValue)
      const index = options.findIndex(i => isEqual(i.value, dateValue))

      if (index < 0) {
        setDateInput(value)
      } else {
        setDateInput()
      }
    }
  }, [value, options])

  return (
    <>
      {typeChange === 'agendadas' ? <></> : (
        <Wrapper data-test='date-input-dropdown'>
          <ButtonDropdown
            value={{ ...date, ...dateInput }}
            defaultValue={defaultValue}
            placeholder={placeholder}
            label={parentLabel || label}
            options={[...options, ...datePickers]}
            data-tracking={`${locationPath} > ${label} filtro por data`}
            onSubmit={handleSubmit}
            onClear={handleClear}
            data-test='date-input-dropdown-btn'
            noBackground={noBackground}
            disable={typeChange === 'agendadas'}
          />
        </Wrapper>
      )}
    </>
  )
}

DateInput.propTypes = {
  onSubmit: PropTypes.func,
  onClear: PropTypes.func,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  defaultValue: PropTypes.object,
  gteLimit: PropTypes.any,
  noBackground: PropTypes.bool,
  parentLabel: PropTypes.any,
  typeChange: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired
}

DateInput.defaultProps = {
  onSubmit: () => { /* noop */ },
  onClear: () => { /* noop */ },
  gteLimit: moment(new Date()).subtract(1, 'year').startOf('day'),
  options: [
    {
      label: 'Hoje',
      value: {
        gte: moment().startOf('day').toDate(),
        lte: moment().endOf('day').toDate()
      }
    },
    {
      label: 'Ontem',
      value: {
        gte: moment().subtract(1, 'day').startOf('day').toDate(),
        lte: moment().subtract(1, 'day').endOf('day').toDate()
      }
    },
    {
      label: 'Essa semana',
      value: {
        gte: moment().startOf('week').toDate(),
        lte: moment().endOf('week').toDate()
      }
    },
    {
      label: 'Semana passada',
      value: {
        gte: moment().subtract(1, 'week').startOf('week').toDate(),
        lte: moment().subtract(1, 'week').endOf('week').toDate()
      }
    },
    {
      label: 'Esse mês',
      value: {
        gte: moment().startOf('month').toDate(),
        lte: moment().endOf('month').toDate()
      }
    },
    {
      label: 'Mês passado',
      value: {
        gte: moment().subtract(1, 'month').startOf('month').toDate(),
        lte: moment().subtract(1, 'month').endOf('month').toDate()
      },
      divisor: true
    }
  ]
}

export default DateInput
