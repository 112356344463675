import React from 'react'
import styled from 'styled-components'

import Label from 'src/UIComponents/Label/Label'

import { darkGray } from 'src/Styles/settings/Constants'
import { biggestText } from '../../Styles/settings/Constants'

const DisabledDashPage = () => {
  return (
    <Wrapper>
      <StyledLabelErrorCode>404</StyledLabelErrorCode>
      <StyledLabelDescription>Página não encontrada</StyledLabelDescription>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100vw;
  min-height: 100vh;
  padding: 8rem;
`
const StyledLabelErrorCode = styled(Label)`
  font-size: 122px;
  font-weight: bolder;
  text-align: center;
  margin-bottom: -35px;
`
const StyledLabelDescription = styled(Label)`
  font-size: ${biggestText};
  color: ${darkGray};
  white-space: pre;
`

export default DisabledDashPage
