import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from '../Button/ButtonDefault'

import { darkGray, normalText, textBold } from '../../Styles/settings/Constants'

const CreateBankingAccount = ({ hasButton, onClick }) => (
  <Container>
    <Text>Até o momento este estabelecimento receberá suas <Bold>transferências</Bold> na <Bold>conta digital</Bold>. Deseja cadastrar uma conta bancária?</Text>
    {hasButton && (
      <ButtonContainer>
        <Button
          link
          widthAuto
          onClick={() => onClick()}
          data-tracking={`CreateBankingAccount`}
          icon='/assets/icons/actions/add.svg'
        >
          Cadastrar Conta Bancária
        </Button>
      </ButtonContainer>
    )}
  </Container>
)

CreateBankingAccount.propTypes = {
  hasButton: PropTypes.bool,
  onClick: PropTypes.func
}

const Container = styled.div`
  width: 100%;
`

const Text = styled.p`
  color: ${darkGray};
  font-size: ${normalText};
`

const Bold = styled.span`
  font-weight: ${textBold};
`

const ButtonContainer = styled.div`
  padding: 1.5rem 0;
`

export default CreateBankingAccount
