import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import { toMoney } from 'src/Utils/Utils'
import { InputCSS } from 'src/UIComponents/Input/Input'

const CurrencyInput = ({
  className,
  value,
  onChange,
  ...props
}) => {
  return (
    <Input className={className} type='text' value={value ? toMoney(value) : ''} onChange={onChange} {...props} />
  )
}

CurrencyInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
}

export default CurrencyInput

const Input = styled.input`
  ${InputCSS}
`
