import React from 'react'
import ContentLoader from 'react-content-loader'

const DocumentsLoader = (props) => (
  <ContentLoader
    width={310}
    height={100}
    viewBox='0 0 310 100'
    speed={4}
    backgroundColor='#F4F4F4'
    foregroundColor='#DCDCDC'
    {...props}
  >
    <rect x='0' y='5' rx='5' ry='5' width='220' height='18' />
    <rect x='0' y='31' rx='5' ry='5' width='220' height='18' />
    <rect x='0' y='69' rx='5' ry='5' width='200' height='30' />
  </ContentLoader>
)

export default DocumentsLoader
