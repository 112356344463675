import React from 'react'
import ReactSVG from 'react-svg'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'

import { darkGray } from 'src/Styles/settings/Constants'

const Chevron = ({ src, color, className }) => {
  return (<Icon src={src || '/assets/icons/arrow/arrow-right.svg'} color={color} className={className} />)
}

export const ChevronUp = ({ small, color, className }) => (
  small
    ? <Chevron src='/assets/icons/arrow/mini-arrow-up.svg' color={color} className={className} />
    : <Chevron src='/assets/icons/arrow/arrow-up.svg' color={color} className={className} />
)

export const ChevronDown = ({ small, color, className }) => (
  small
    ? <Chevron src='/assets/icons/arrow/mini-arrow-down.svg' color={color} className={className} />
    : <Chevron src='/assets/icons/arrow/arrow-down.svg' color={color} className={className} />
)

export const ChevronLeft = ({ small, color, className }) => (
  small
    ? <Chevron src='/assets/icons/arrow/mini-arrow-left.svg' color={color} className={className} />
    : <Chevron src='/assets/icons/arrow/arrow-left.svg' color={color} className={className} />
)

export const ChevronRight = ({ small, color, className }) => (
  small
    ? <Chevron src='/assets/icons/arrow/mini-arrow-right.svg' color={color} className={className} />
    : <Chevron src='/assets/icons/arrow/arrow-right.svg' color={color} className={className} />
)

ChevronUp.propTypes = {
  small: PropTypes.bool,
  color: PropTypes.string,
  className: PropTypes.string
}

ChevronDown.propTypes = {
  small: PropTypes.bool,
  color: PropTypes.string,
  className: PropTypes.string
}

ChevronLeft.propTypes = {
  small: PropTypes.bool,
  color: PropTypes.string,
  className: PropTypes.string
}

ChevronRight.propTypes = {
  small: PropTypes.bool,
  color: PropTypes.string,
  className: PropTypes.string
}

Chevron.propTypes = {
  src: PropTypes.string,
  color: PropTypes.string,
  className: PropTypes.string
}

const Icon = styled(ReactSVG)`
  svg {
    width: 12px;
    height: 12px;
    fill: ${({ color, primary, theme }) => `${color || (primary && theme.color) || darkGray}`};
  }
`

export default Chevron
