import React from 'react'
import * as PropTypes from 'prop-types'
import styled from 'styled-components'

import { toMoney } from 'src/Utils/Utils'
import { InputCSS } from 'src/UIComponents/Input/Input'

const CurrencyInput = ({ className, value, onChange, ...props }) => {
  const handleValue = (value) => {
    if (value || value === 0) {
      return toMoney(value)
    }
    return value
  }

  return <Input className={className} type='text' value={handleValue(value)} onChange={onChange} {...props} />
}

CurrencyInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func
}

export default CurrencyInput

const Input = styled.input`
  ${InputCSS}
`
