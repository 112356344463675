import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactSVG from 'react-svg'

import Grid from 'src/UIComponents/Grid/Grid'
import Button from 'src/UIComponents/Button/ButtonDefault'
import Label from 'src/UIComponents/Label/Label'
import SubTitle from 'src/UIComponents/Title/SubTitle'
import FormZ from 'src/UIComponents/FormZ/FormZ'
import InputDefaultFactory from 'src/Components/FormFactories/InputDefaultFactory'
import PhoneFactory from 'src/Components/FormFactories/PhoneFactory'
import SupportInfoTemplate from 'src/UIComponents/TemplatesForm/SupportInfoTemplate'
import { toPhone } from 'src/Utils/Utils'
import { getFormOptionsWithValidation } from 'src/Utils/FormUtils'

import { darkGray, smallerText } from 'src/Styles/settings/Constants'

const SupportInfo = ({
  custom: {
    logo,
    temporaryColoredLogo,
    updateSupportDataRequesting
  },
  disabled,
  hideButtonEdit,
  formValues,
  handleOnChange,
  handleSubmit,
  handleEdit,
  handleCancel,
  showValidation,
  struct
}) => {
  let options = {
    template: (locals) => SupportInfoTemplate(locals),
    fields: {
      email: {
        label: 'Email do Suporte',
        factory: InputDefaultFactory,
        autocomplete: false,
        attrs: {
          maxLength: 254
        },
        config: {
          id: 'email',
          valid: false,
          placeholder: ' ',
          disabled: disabled
        }
      },
      phoneNumber: {
        label: 'Telefone do Suporte',
        factory: PhoneFactory,
        autocomplete: false,
        config: {
          id: 'suport-phone-number',
          placeholder: ' ',
          disabled: disabled
        }
      }
    }
  }

  const coloredLogoSource = temporaryColoredLogo ? temporaryColoredLogo.preview : logo

  return (
    <Grid.Row>
      <Grid.Col offset={1} cols={15}>
        <Grid.Row>
          <Grid.Col cols={15}>
            <SubTitle line>Dados <strong>do Suporte</strong></SubTitle>
          </Grid.Col>
          <Grid.Col cols={1}>
            {!hideButtonEdit && <EditButton
              onClick={() => handleEdit('supportInfo')}
              icon='/assets/icons/actions/edit.svg'
              ghost
              outline
            >
              Editar
            </EditButton>}
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col cols={7}>
            <Label color={darkGray}>Informe os dados dos seus canais de suporte. Essas informações são exibidas em algumas comunicações automáticas enviadas por e-mail ou SMS para os estabelecimentos e seus clientes.</Label>
          </Grid.Col>
        </Grid.Row>
        <br />
        <Grid.Row>
          <Grid.Col cols={16}>
            <Wrapper>
              <SupportInfoImgWrapper>
                <SupportInfoImg color={coloredLogoSource} src='/assets/imgs/support-info.svg' />
                <Logo src={coloredLogoSource} />
                <Title fontSize={smallerText} color={darkGray} bold uppercase textAlign='center'>Fale Conosco</Title>
                <Email fontSize={smallerText} color={darkGray} textAlign='center'>{formValues.email}</Email>
                <Phone fontSize={smallerText} color={darkGray} textAlign='center'>{toPhone(formValues.phoneNumber || '')}</Phone>
              </SupportInfoImgWrapper>
              <FormWrapper>

                <br />
                <FormZ
                  options={
                    showValidation
                      ? getFormOptionsWithValidation(options, formValues, struct)
                      : options
                  }
                  struct={struct}
                  values={formValues}
                  onChange={handleOnChange}
                  disabledForm={disabled}
                />
                {!disabled && <ButtonsContainer>
                  <Button onClick={handleCancel} inversed>Cancelar</Button>
                  <Button isLoading={updateSupportDataRequesting} onClick={handleSubmit}>Salvar Alterações</Button>
                </ButtonsContainer>}
              </FormWrapper>
            </Wrapper>
          </Grid.Col>
        </Grid.Row>
      </Grid.Col>
    </Grid.Row>
  )
}

SupportInfo.propTypes = {
  custom: PropTypes.object,
  handleEdit: PropTypes.func,
  disabled: PropTypes.bool,
  formValues: PropTypes.object,
  handleOnChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleCancel: PropTypes.func,
  showValidation: PropTypes.bool,
  struct: PropTypes.func,
  hideButtonEdit: PropTypes.bool
}

export default SupportInfo

const Wrapper = styled.div`
  display: flex;
`

const SupportInfoImgWrapper = styled.div`
  position: relative;
  width: fit-content;
  margin-top: 1rem;
  margin-right: 22px;
`

const FormWrapper = styled.div`
  width: 250px;
`

const EditButton = styled(Button)`
  display: flex;
  width: 88px;
  margin-top: -7px;
`

const SupportInfoImg = styled(ReactSVG)`
  svg [fill='#FF9A00'] {
    fill: ${props => props.theme.color};
  }
`

const ButtonsContainer = styled.div`
  display: flex;
  width: 300px;
  margin-top: 40px;

  button:first-child {
    margin-right: 5px;
  }
`

const Logo = styled.img`
  position: absolute;
  width: 60px;
  top: 30px;
  left: 127px;
`

const Title = styled(Label)`
  position: absolute;
  bottom: 52px;
  width: 100%;
  justify-content: center;
`

const Email = styled(Label)`
  position: absolute;
  bottom: 38px;
  width: 100%;
  justify-content: center;
`

const Phone = styled(Label)`
  position: absolute;
  bottom: 22px;
  width: 100%;
  justify-content: center;
`
