import styled from 'styled-components'

import {
  middlePadding, white
} from 'src/Styles/settings/Constants'

export const Wrapper = styled.div`
  .dropdown-menu {
    min-width: 180px;
  }
`

export const DatePickerInputContainer = styled.div`
  padding: 0 ${middlePadding};
  margin: 4rem 0;
  position: relative;

  & > :nth-child(2) {
    position: absolute;
    top: 0;
    left: 106%;

    &:after {
      content: "";
      position: absolute;
      left: -3.7%;
      top: 10%;
      border-top: 8px solid transparent;
      border-right: 8px solid ${white};
      border-left: none;
      border-bottom: 8px solid transparent;
    }
  }
`
