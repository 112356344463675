import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import * as PropTypes from 'prop-types'

import { biggerText, darkerGray, margin, normalText } from '../../Styles/settings/Constants'
import ButtonDefault from '../Button/ButtonDefault'
import Label from '../Label/Label'
import { Modal } from '../Modal/Modal'

const CreateSlugDisclaimerModal = ({
  handleOnClose
}) => {
  const history = useHistory()

  const onClose = () => {
    sessionStorage.setItem('hideCreateSlug', true)
    handleOnClose()
  }

  return (
    <Modal handleOnClose={onClose} styleProps={{ maxWidth: '40vw', height: 'auto' }}>
      <TitleContainer>
        <Label fontSize={biggerText} bold>Defina o prefixo da sua URL</Label>
      </TitleContainer>
      <ContentContainer>
        <Label color={darkerGray} fontSize={normalText}>
          Não encontramos um prefixo de URL definido para o seu marketplace.
          <br />
          Clique no botão abaixo para definir um endereço personalizado para ter acesso ao <strong>Dash</strong> e <strong>Minha Conta</strong>.</Label>
      </ContentContainer>
      <ButtonContainer>
        <ButtonDefault onClick={() => history.push('/configuracoes/personalizacao')}>
          Escolher agora
        </ButtonDefault>
      </ButtonContainer>
    </Modal>
  )
}

export default CreateSlugDisclaimerModal

CreateSlugDisclaimerModal.propTypes = {
  handleOnClose: PropTypes.func
}

const TitleContainer = styled.div`
  margin-bottom: ${margin};
`

const ContentContainer = styled.div`
  margin-bottom: ${margin};
`

const ButtonContainer = styled.div``
