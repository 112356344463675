import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

export default function CheckboxSingle({ checked = false }) {
  return (
    // eslint-disable-next-line no-unused-vars
    <Radio className='custom__checkbox' type='checkbox' checked={checked} onChange={(e) => {}} />
  )
}

CheckboxSingle.propTypes = {
  checked: PropTypes.bool
}

const Radio = styled.input`
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #d410c2;
    background-color: #fff; 
    outline: none;
    cursor: pointer;
    transition: 0.5s all;
    position: relative

    :after {
        content: "";
        border-radius: 50%;

      transition: 0.5s all;
    }


    &:checked:after {
        content: "";
        position: absolute;
        top:2px;
        left: 2px; 
        right: 2px;
        width: 12px; 
        height: 12px; 
        border-radius: 50%;
        background-color: #d410c2; /* Change the background color when checked */
        transition: 1s all;
    }
`
