import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'
import ReactSVG from 'react-svg'
import { fadeIn } from 'react-animations'

import {
  white,
  normalText,
  smallText,
  textBold,
  smallPadding
} from '../../Styles/settings/Constants'

const DisplaySeller = ({ seller, onClick, className }) => {
  const name =
    seller.type === 'individual'
      ? `${seller.first_name} ${seller.last_name}`
      : seller.statement_descriptor
  const document =
    seller.type === 'individual'
      ? seller.taxpayer_id.toCPF()
      : seller.ein?.toCnpj()

  return (
    <Container
      className={className}
      onClick={() => onClick()}
      data-test='select-seller-selected'
    >
      <CloseSidebarIcon
        src='/assets/icons/actions/close.svg'
        data-test='display-seller-close-btn'
      />
      <Header>{name ? name.toLowerCase() : ''}</Header>
      {seller.type === 'business' && (
        <Text>
          {seller.business_name ? seller.business_name.toLowerCase() : ''}
        </Text>
      )}
      <Text>{document}</Text>
    </Container>
  )
}

DisplaySeller.propTypes = {
  seller: PropTypes.object,
  onClick: PropTypes.func,
  className: PropTypes.string
}

const fadeAnimation = keyframes`${fadeIn}`

const Container = styled.div`
  width: 100%;
  position: relative;
  border-radius: 5px;
  border: 1px solid ${({ theme: { color } }) => color};
  padding: ${smallPadding};
  background-color: ${white};
  animation: 0.3s ${fadeAnimation};
  cursor: pointer;
`

const Header = styled.p`
  color: ${({ theme: { color } }) => color};
  font-size: ${normalText};
  font-weight: ${textBold};
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const Text = styled.p`
  color: ${({ theme: { color } }) => color};
  font-size: ${smallText};
  text-transform: capitalize;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const CloseSidebarIcon = styled(ReactSVG)`
  cursor: pointer;
  float: right;

  svg {
    width: 10px;
    height: 10px;
    fill: ${({ theme: { color } }) => color};
  }
`

export default DisplaySeller
