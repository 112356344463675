import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { normalText, darkGray, lightGray } from 'src/Styles/settings/Constants'

const CheckboxForm = ({ id, checked, partialChecked, label, onClick, line, ...props }) => (
  <Container>
    <Checkbox
      line={line}
      type='checkbox'
      id={id}
      checked={checked}
      partialChecked={partialChecked}
      onClick={onClick}
      data-test={`checkbox-${id}`}
      data-tracking={`checkbox-${label}`}
      {...props}
    />
    <label data-tracking={`checkbox-${label}`} htmlFor={id} onClick={onClick}>{label}</label>
    {line && (
      <Line />
    )}
  </Container>
)

CheckboxForm.propTypes = {
  id: PropTypes.string,
  checked: PropTypes.bool,
  partialChecked: PropTypes.bool,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  onClick: PropTypes.func,
  line: PropTypes.bool
}

const Container = styled.div`
  width: 100%;
  display: flex;
`

const Line = styled.div`
  height: 1px;
  width: 100%;
  margin: auto;
  background-color: ${lightGray};
`

const Checkbox = styled.span`
  margin-right: 7px;
  content: url(/assets/icons/actions/select-${props => (props.checked ? 'checked' : 'unchecked') + (props.white ? '-white' : '')}.svg);
  vertical-align: text-top;
  cursor: pointer;

  + label {
    font-size: ${normalText};
    color: ${darkGray};
    cursor: pointer;
    align-self: center;

    ${({ line }) => line && css`
      white-space: nowrap;
      margin-right: 5px;
  `}
  }

  ${({ partialChecked }) => partialChecked && css`
    path {
      fill: #C2C2C2;
    }
  `}
`

export default CheckboxForm
