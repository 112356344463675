import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import ReactSVG from 'react-svg'

import { lightGray } from 'src/Styles/settings/Constants'

const IconButton = ({ primary, small, src, onClick, disabled }) => (
  <Wrapper disabled={disabled}>
    <SVG disabled={disabled} primary={primary} small={small} src={src} onClick={!disabled ? onClick : undefined} />
  </Wrapper>
)

IconButton.propTypes = {
  primary: PropTypes.bool,
  small: PropTypes.bool,
  src: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

const Wrapper = styled.button`
  background: 0;
  border: 0;
  cursor: pointer;
  height: 100%;
  margin-left: 4px;

  &:focus {
    outline: none;
  }

  :disabled {
    cursor: not-allowed;
  }
`

const SVG = styled(ReactSVG)`
  ${({ primary }) => primary && css`
    svg {
      fill: ${props => props.theme.color};
    }
  `}

  ${({ small }) => small && css`
    svg {
      width: 8px!important;
      height: 8px!important;
    }
  `}

  ${({ disabled }) => disabled && css`
    svg {
      fill: ${lightGray};
    }
  `}
`

export default IconButton
