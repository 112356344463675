import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import appConfig from '../../Configs/appConfig'
import Loading from '../Dots/Dots'
import requestApi from '../../Configs/request'
import uriRequests from '../../Configs/uriRequests'
import sellerErrors from '../../Errors/sellerErrors'
import takeError from '../../Errors/takeError'
import createToast from '../../Utils/createToast'
import PDFViewer from '../PDFViewer'

export const DocumentPreview = (props) =>
  props.mimeType === 'application/pdf' ? (
    <Pdf
      url={uriRequests.downloadSellerDocument.replace('{documentId}', props.id)}
    />
  ) : (
    <Bitmap {...props} />
  )

const Bitmap = ({ id, alt }) => {
  const { isLoading, src } = useDocumentPreview(id)
  return isLoading ? (
    <Loading outline color={'#ccc'} />
  ) : (
    <img alt={alt} src={src} />
  )
}

const Pdf = ({ url }) => {
  const { token, marketplaceId } = useSelector(
    ({ authentication, marketplace }) => ({
      token: authentication.token,
      marketplaceId: marketplace.id
    })
  )

  const file = {
    url: `${appConfig.hostApi}/v1/marketplaces/${marketplaceId}/${url}`,
    httpHeaders: {
      Authorization: `Bearer ${token}`
    }
  }

  return <PDFViewer file={file} />
}

DocumentPreview.propTypes = {
  id: PropTypes.string,
  alt: PropTypes.string,
  mimeType: PropTypes.string
}

Bitmap.propTypes = {
  id: PropTypes.string,
  alt: PropTypes.string
}

Pdf.propTypes = {
  url: PropTypes.string
}

const useDocumentPreview = (id) => {
  const [isLoading, setisLoading] = useState()
  const [src, setSrc] = useState()

  useEffect(() => {
    if (id) {
      setisLoading(true)
      getDocumentObject(id)
        .then(setSrc)
        .finally(() => setisLoading(false))
    }
  }, [id])

  return {
    isLoading,
    src
  }
}

export const getDocumentObject = async (documentId) => {
  const uri = uriRequests.downloadSellerDocument.replace(
    '{documentId}',
    documentId
  )

  return requestApi
    .get(uri, {
      responseType: 'arraybuffer',
      headers: {
        Accept: 'binary/octet-stream'
      }
    })
    .then((response) => window.URL.createObjectURL(new Blob([response.data])))
    .catch((error) => {
      const e = takeError(error, sellerErrors)
      if (e.status === 500) {
        e.message =
          'Não conseguimos fazer o download do documento. Por favor, tente novamente.'
      }
      createToast('error', e.message, '', e.status)
      throw error
    })
}
