import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Card from 'src/UIComponents/Card/Card'
import { darkGray, gray60Percent, lightestGray, lightGray } from 'src/Styles/settings/Constants'
import Button from 'src/UIComponents/Button/ButtonDefault'
import Icon from 'src/UIComponents/Icon/Icon'
import FileDrop from 'src/Components/FileDrop/FileDrop'
import AlertMessage from 'src/UIComponents/AlertMessage/AlertMessage'
import DocumentsLoader from 'src/UIComponents/DocumentCard/DocumentsLoader'
import { bytesToSize } from 'src/Utils/Utils'
import Can from 'src/Helpers/Permission/Can'

import { DocumentPreview } from './Preview'

const DocumentCard = (
  {
    header,
    description,
    loadingContent,
    loadingRequest,
    files,
    onFileSubmit,
    onDownloadFile,
    onCancelSubmit,
    error,
    ...props
  }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [file, setFile] = useState()
  const [fileError, setFileError] = useState()
  const [showFiles, setShowFiles] = useState(false)

  const handleOnDrop = (files = []) => {
    const file = files.length > 0 && files[0]

    if (file) {
      setFile(file)
    }
  }

  const handleFileSizeError = (hasError) => {
    let error

    if (hasError) {
      error = {
        message: {
          title: 'Esse arquivo é muito grande',
          message: 'Envie um arquivo com no máximo 2MB'
        }
      }
    }

    setFileError(error)
  }

  const handleFileMimeTypeError = (hasError) => {
    let error

    if (hasError) {
      error = {
        message: {
          title: 'Formato de arquivo inválido',
          message: 'Envie um arquivo nos formatos PNG, PDF ou JPG'
        }
      }
    }
    setFileError(error)
  }

  useEffect(() => {
    if (!isOpen) {
      setFile()
      setFileError()
      setShowFiles(false)
    }
  }, [isOpen])

  const sendDocumentButtonText = files.length > 0 ? 'Enviar outro documento' : 'Enviar documento'
  const documentsDescription = () => {
    if (!isOpen && showFiles && files.length > 0) {
      return (
        <List isOpen>
          {files.map(({ id, name, mime_type: mimeType, size }) => (
            <ListItem>
              <DocumentContainer>
                <a onClick={() => onDownloadFile(id, name)} download={name}>
                  <DocumentPreview mimeType={mimeType} alt={name} id={id} />
                </a>
              </DocumentContainer>
              <a onClick={() => onDownloadFile(id, name)} download={name}>{`${name} (${bytesToSize(size)})`}</a>
            </ListItem>
          ))}
        </List>
      )
    }
    if (!isOpen && files.length > 0) {
      return (
        <List>
          {files.map(({ id, name, size }) => (
            <li><a onClick={() => onDownloadFile(id, name)} download={name}>{`${name} (${bytesToSize(size)})`}</a></li>
          ))}
        </List>
      )
    }
    return description
  }

  return (
    <Can I='update' a='Seller' passThrough>
      {(can) => (
        <CardContainer hasPermission={can} outline={!isOpen} withShadow={isOpen} borderColor={gray60Percent} isOpen={isOpen || showFiles}>
          <Header>
            <Title>{header}</Title>
            {!isOpen && files.length > 0 &&
            <span data-tracking={`${props['data-tracking']} > ShowDocuments`}>
              <EyeToggle
                src={`/assets/icons/actions/${showFiles ? 'hide' : 'show'}.svg`}
                height='24px'
                onClick={() => setShowFiles(!showFiles)} />
            </span>
            }
          </Header>
          {!loadingContent &&
            <Description>
              {documentsDescription()}

              {isOpen &&
              <FormatAndSizeWarning>
                <span>
                Envie os documentos nos formatos <strong>PNG</strong>, <strong>PDF</strong> ou <strong>JPG</strong>, com no máximo <strong>2MB</strong>
                </span>
              </FormatAndSizeWarning>}
            </Description>}
          {isOpen &&
          <FileDropContainer data-tracking={`${props['data-tracking']} > Selecionar arquivo`}>
            <FileDrop
              changeFileLabel='Alterar arquivo'
              categoryIconSource='/assets/icons/actions/drop-file.svg'
              maxSize={2 * 1024 * 1024}
              onMaxSizeError={handleFileSizeError}
              onMimeTypeError={handleFileMimeTypeError}
              supportedMimeTypes={['image/png', 'image/jpg', 'image/jpeg', 'application/pdf']}
              file={file ? file.preview : undefined}
              onDrop={handleOnDrop} />
            {fileError && <AlertMessage error={fileError} type='error' />}
          </FileDropContainer>}
          {loadingContent && !isOpen &&
          <LoadingContainer>
            <DocumentsLoader />
          </LoadingContainer>}
          {can && !isOpen && !loadingContent && !showFiles && <Button data-tracking={`${props['data-tracking']} > Send${files.length > 0 ? ' > Another' : ''}`} inversed widthAuto onClick={() => setIsOpen(true)}>
            {sendDocumentButtonText}
          </Button>}
          {can && isOpen &&
          <ButtonsContainer>
            <Button
              data-tracking={`${props['data-tracking']} > Cancelar`}
              data-test='document-card-cancel-btn'
              inversed
              widthAuto
              onClick={() => {
                onCancelSubmit()
                setIsOpen(false)
              }}
            >
              Cancelar
            </Button>
            <Button
              disabled={!file}
              widthAuto
              isLoading={loadingRequest}
              data-tracking={`${props['data-tracking']} > Enviar documento${error ? ' > Tentar novamente' : ''}`}
              data-test='document-card-send-btn'
              onClick={async () => {
                try {
                  await onFileSubmit(file.file)
                  setIsOpen(false)
                } catch (e) {
                  /* noop */
                }
              }}
            >
              {error ? 'Tente novamente' : 'Enviar documento'}
            </Button>
          </ButtonsContainer>}
        </CardContainer>
      )}
    </Can>
  )
}

DocumentCard.defaultProps = {
  files: [],
  onFileSubmit: () => { /* noop */ },
  onCancelSubmit: () => { /* noop */ }
}

DocumentCard.propTypes = {
  header: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  loadingContent: PropTypes.bool,
  loadingRequest: PropTypes.bool,
  files: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    path: PropTypes.string
  })),
  onFileSubmit: PropTypes.func,
  onDownloadFile: PropTypes.func,
  onCancelSubmit: PropTypes.func,
  'data-tracking': PropTypes.string,
  error: PropTypes.string
}

const CardContainer = styled(Card)`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  justify-content: ${({ hasPermission }) => hasPermission ? 'space-between' : 'initial'};
  min-height: 223px;
  max-height: ${({ isOpen }) => isOpen ? 'auto' : '223px'};
  overflow: hidden;
  transition: all 0.5s ease-in-out;
`

const Header = styled.div`
  width: 100%;
  display:flex;
  justify-content: space-between;
`

const Title = styled.p`
  font-size: 1.6rem;
`

const EyeToggle = styled(Icon)`
  padding: 0;
`

const Description = styled.p`
  width: 100%;
  font-size: 1.4rem;
  margin: 2rem 0 2rem 0;
`

const FormatAndSizeWarning = styled.div`
  background-color: ${lightestGray};
  padding: 1rem 2rem;
  margin: 2rem 0 0 0;
  border-radius: 0.5rem;
  text-align: center;
`

const FileDropContainer = styled.div`
  width: 100%;
  margin-bottom: 2rem;
`

const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: flex-start;
  padding: 4rem 0;
`

const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`

const List = styled.ul`
  list-style: none;
  max-height: ${({ isOpen }) => isOpen ? 'unset' : '80px'};
  overflow: auto;

  a {
    cursor: pointer;
    color: ${darkGray};

    &:hover {
      color: ${darkGray};
      font-weight: bold;
      text-decoration: none;
    }
  }
`

const DocumentContainer = styled.div`
  min-height: 150px;
  background-color: ${lightGray};
  text-align: center;
  border-radius: 5px;
  margin-bottom: 0.6rem;

  img, iframe, a {
    min-height: 150px;
    height: 100%;
    width: 300px;
    object-fit: contain;
  }

  a {
    display: flex;
    justify-content: center;
    align-items: center;
    justify-items: center;
    align-content: center;
    width: 100%;
  }
`

const ListItem = styled.li`
  &:not(:first-child) {
    margin-top: 1rem;
  }
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
`

export { DocumentCard }
