import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ReactSVG from 'react-svg'

import Grid from 'src/UIComponents/Grid/Grid'
import Label from 'src/UIComponents/Label/Label'
import SubTitle from 'src/UIComponents/Title/SubTitle'
import Button from 'src/UIComponents/Button/ButtonDefault'

import { darkGray } from 'src/Styles/settings/Constants'

const VisualIdentity = ({
  custom: {
    color,
    temporaryColor,
    logo,
    temporaryColoredLogo,
    monochromeLogo,
    temporaryMonochromeLogo
  },
  handleEdit,
  hideButtonEdit
}) => {
  const coloredLogoSource = temporaryColoredLogo ? temporaryColoredLogo.preview : logo
  const monochromeLogoSource = temporaryMonochromeLogo ? temporaryMonochromeLogo.preview : monochromeLogo
  const colorHex = temporaryColor || color

  return (
    <Grid.Row>
      <Grid.Col offset={1} cols={16}>
        <Grid.Row>
          <Grid.Col cols={15}>
            <SubTitle line>Sua <strong>Cor e Logo</strong></SubTitle>
          </Grid.Col>
          <Grid.Col cols={1}>
            {!hideButtonEdit && <EditButton
              ghost
              outline
              onClick={() => handleEdit('visualIdentity')}
              icon='/assets/icons/actions/edit.svg'
            >
              Editar
            </EditButton>}
          </Grid.Col>
        </Grid.Row>
        <Grid.Row>
          <Grid.Col cols={5}>
            <Label color={darkGray}>Para customizar os seus produtos com a sua cor e logo, precisamos que você
              nos envie a sua logo e defina a cor principal da sua marca.</Label>
          </Grid.Col>
        </Grid.Row>
        <Grid.Row noMargin>
          <Grid.Col cols={8}>
            <ImgContainer>
              <TemplateImg color={colorHex} src='/assets/imgs/template.svg' />
              <POSImg color={colorHex} src='/assets/imgs/pos-full.svg' />
              <LogoTemplate src={coloredLogoSource} />
              <LogoPOS src={coloredLogoSource} />
              <LogoReceipt src={monochromeLogoSource} />
            </ImgContainer>
          </Grid.Col>
        </Grid.Row>
      </Grid.Col>
    </Grid.Row>
  )
}

VisualIdentity.propTypes = {
  custom: PropTypes.object,
  handleEdit: PropTypes.func,
  hideButtonEdit: PropTypes.bool
}

export default VisualIdentity

const LogoTemplate = styled.img`
  position: absolute;
  width: 32px;
  top: 11px;
  left: 5px;
`

const EditButton = styled(Button)`
  display: flex;
  width: 88px;
  margin-top: -7px;
`

const LogoPOS = styled.img`
  position: absolute;
  width: 40px;
  top: 110px;
  right: 24px;
`

const LogoReceipt = styled.img`
  position: absolute;
  width: 40px;
  top: 8px;
  right: 24px;
`

const ImgContainer = styled.div`
  display: flex;
  position: relative;
  width: fit-content;
`

const TemplateImg = styled(ReactSVG)`
  margin-right: 40px;

  svg [fill='#FF9A00'] {
    fill: ${props => props.theme.color};
  }
`

const POSImg = styled(ReactSVG)`
  svg [fill='#FF9A00'] {
    fill: ${props => props.theme.color};
  }
`
