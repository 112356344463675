import styled from 'styled-components'

import { smallText } from 'src/Styles/settings/Constants'

const CheckboxInput = styled.input.attrs(() => ({
  type: 'checkbox'
}))`
  display: none;

  + label {
    font-size: ${smallText};
  }

  &:checked + label {
    color: ${props => props.theme.color};
  }
`

export default CheckboxInput
