import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import Label from 'src/UIComponents/Label/Label'
import Button from 'src/UIComponents/Button/ButtonDefault'

import { darkGray, white, bigText, normalPadding } from 'src/Styles/settings/Constants'

const Disclaimer = ({ children, handleCancel, handleSubmit, position, labelCancel, labelSave, noBottomSize, isLoading }) => {
  return (
    <DisclaimerContainer noBottomSize={noBottomSize}>
      <DisclaimerPosition position={position}>
        <DisclaimerCard data-test='disclaimer-card'>
          <DisclaimerLabel fontSize={bigText} color={darkGray}>{children}</DisclaimerLabel>
          <ButtonsContainer>
            { handleCancel && <Button onClick={handleCancel} inversed data-test='disclaimer-cancel-btn'>{labelCancel}</Button> }
            { handleSubmit && <Button onClick={handleSubmit} isLoading={isLoading} data-test='disclaimer-save-btn'>{labelSave}</Button> }
          </ButtonsContainer>
        </DisclaimerCard>
      </DisclaimerPosition>
    </DisclaimerContainer>
  )
}

Disclaimer.defaultProps = {
  labelCancel: 'Descartar',
  labelSave: 'Salvar',
  position: 'middle'
}

Disclaimer.propTypes = {
  children: PropTypes.node,
  handleCancel: PropTypes.func,
  handleSubmit: PropTypes.func,
  labelCancel: PropTypes.string,
  labelSave: PropTypes.string,
  noBottomSize: PropTypes.bool,
  isLoading: PropTypes.bool,
  position: PropTypes.oneOf(['middle', 'bottom', 'baseline'])
}

export default Disclaimer

const DisclaimerContainer = styled.div`
  position: fixed;
  display: table;
  z-index: 999;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(78, 78, 78, .5);
`

const DisclaimerPosition = styled.div`
  display: table-cell;
  vertical-align: ${({ position }) => position};

  ${({ position }) => position === 'bottom' && css`
    padding-bottom: 3rem;
  `}

  ${({ position }) => position === 'baseline' && css`
    padding-top: 3rem;
  `}
`

const DisclaimerCard = styled.div`
  background-color: ${white};
  width: fit-content;
  margin: auto;
  padding: ${normalPadding};
  border-radius: 5px;
  box-shadow: 0px 3px 7px rgba(0, 0, 0, 0.07);
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const DisclaimerLabel = styled(Label)`
  line-height: 2.2rem;
  display: block;
`

const ButtonsContainer = styled.div`
  display: flex;
  margin-left: 100px;
  align-items: center;

  button:first-child {
    margin-right: 5px;
  }
`
