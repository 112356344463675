import styled from 'styled-components'
import 'react-date-range/dist/styles.css' // main style file
import 'react-date-range/dist/theme/default.css' // theme css file
import { Calendar } from 'react-date-range'
import { ptBR } from 'date-fns/locale'
import * as PropType from 'prop-types'

import {
  lightGray,
  darkerGray,
  darkGray,
  textBold
} from 'src/Styles/settings/Constants'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

const DatePicker = ({ onChange, date: dateProp, maxDate, minDate, shownDate }) => {
  const [date, setDate] = useState(dateProp)
  const { color } = useSelector(({ custom }) => ({
    color: custom.color
  }))

  const onChangeDate = (date) => {
    setDate(date)

    onChange && onChange(date)
  }

  useEffect(() => {
    setDate(dateProp)
  }, [dateProp])

  return (
    <Container>
      <StyledCalendar
        date={date}
        locale={ptBR}
        maxDate={maxDate}
        minDate={minDate}
        color={color}
        showMonthAndYearPickers={false}
        onChange={item => onChangeDate(item)}
        shownDate={shownDate}
        data-test='date-picker-calendar'
      />
    </Container>)
}

DatePicker.defaultProps = {
  date: null
}

DatePicker.propTypes = {
  onChange: PropType.func,
  date: PropType.object,
  maxDate: PropType.object,
  minDate: PropType.object,
  shownDate: PropType.object
}

const Container = styled.div`
  .rdrCalendarWrapper {
    box-shadow: 0px 3px 7px ${lightGray};
    border-radius: 12px;
  }
`

const StyledCalendar = styled(Calendar)`
  .rdrMonthAndYearWrapper {
    justify-content: space-between;

    .rdrNextPrevButton {
      background-repeat: no-repeat;
      background-color: transparent;

      i {
        display: none;
      }

      :focus {
        outline: none;
      }
    }

    .rdrPprevButton {
      background-image: url("../../assets/icons/arrow/arrow-left.svg") !important;
      background-position: center;
    }

    .rdrNextButton {
      background-image: url("../../assets/icons/arrow/arrow-right.svg") !important;
      background-position: center;
    }
  }

  .rdrMonthAndYearPickers {
    text-transform: uppercase;
    color: ${darkerGray};

    select {
      padding: 0;
      text-transform: capitalize;
      text-align: end;

      option {
        text-align: end;
      }
    }
  }

  .rdrDayToday {
    .rdrDayNumber {
      span::after {
        // background: ${({ theme: { color } }) => color}; - Isso aqui ainda vai voltar, vcs vão ver
        background: transparent !important;
      }
    }
  }

  .rdrDayNumber {
    span {
      color: ${darkGray};
      line-height: 1em;
    }
  }

  .rdrDays {
    .rdrDayPassive {
      .rdrDayNumber {
        span {
          color: transparent !important;
        }
      }
    }
  }

  .rdrDay {
    line-height: 2.7em;
    height: 2.7em;
  }

  .rdrWeekDays {
    justify-content: space-around;

    .rdrWeekDay {
      display: block;
      text-transform: uppercase;
      color: ${darkerGray};
      text-overflow: ellipsis;
      width: 12px;
      overflow: hidden;
      letter-spacing: 10px;
      flex-basis: unset;
      font-weight: ${textBold};
    }
  }

  .rdrMonth {
   width: 215px;
  }
`

export default DatePicker
