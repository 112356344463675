import React from 'react'
import PropTypes from 'prop-types'
import styled, { css, keyframes } from 'styled-components'

import { buttonFontSize, white, darkGray } from 'src/Styles/settings/Constants'

const Dots = ({ outline, color, light, mini, className }) => (
  <Spinner outline={outline} color={color} className={className}>
    <Dot light={light} mini={mini} />
    <Dot light={light} mini={mini} />
    <Dot light={light} mini={mini} />
  </Spinner>
)

Dots.propTypes = {
  outline: PropTypes.bool,
  color: PropTypes.string,
  light: PropTypes.bool,
  mini: PropTypes.bool,
  className: PropTypes.any
}

const showHideDot = keyframes`
  0% {opacity: 0}
  50% {opacity: 1}
  60% {opacity: 1}
  100% {opacity: 0}
`

const Dot = styled.div`
  animation: ${showHideDot} 1s ease-in-out infinite;
  border-radius: 50%;
  display: inline-block;
  opacity: 0;
  position: relative;
  margin-right: ${({ mini }) => mini ? '4px' : '7px'};
  height: ${({ mini }) => mini ? '4px' : '7px'};
  width: ${({ mini }) => mini ? '4px' : '7px'};
  background-color: ${({ theme: { color }, light }) => light ? white : color};
`

const Spinner = styled.div`
  align-items: center;
  background-color: transparent;
  box-sizing: border-box;
  display: flex;
  height: ${buttonFontSize};
  justify-content: center;
  left: 0;
  top: 0;
  width: auto;

  ${Dot} {
    ${({ outline }) => outline && css`
      background-color: ${({ color }) => color || darkGray};
    `}
    &:first-child {
      animation-delay: 0.2s;
    }
    &:nth-child(2) {
      animation-delay: 0.4s;
    }
    &:last-child {
      animation-delay: 0.6s;
      margin-right: 0;
    }
  }
`

export default Dots
