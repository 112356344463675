import React from 'react'
import * as PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { Collapse as ReactCollapse } from 'reactstrap'

import Title from 'src/UIComponents/Title/Title'
import { ChevronDown, ChevronUp } from 'src/UIComponents/Chevron/Chevron'
import { lightGray, normalPadding } from 'src/Styles/settings/Constants'

const Collapse = ({ children, title, isOpen, onClick, ...props }) => {
  return (
    <CollapseContainer>
      <TitleContainer isOpen={isOpen} onClick={onClick} {...props}>
        <Title small bar>
          { title }
        </Title>
        { isOpen ? <ChevronUp /> : <ChevronDown /> }
      </TitleContainer>
      <ReactCollapse isOpen={isOpen}>
        { children }
      </ReactCollapse>
    </CollapseContainer>
  )
}

export default Collapse

Collapse.propTypes = {
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  isOpen: PropTypes.bool,
  onClick: PropTypes.func
}

const CollapseContainer = styled.div``

const TitleContainer = styled.div`
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid ${lightGray};
  padding: ${normalPadding} 0;

  ${({ isOpen }) => isOpen && css`
    border-bottom: 0;
  `}
`
